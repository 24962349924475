


































































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { ChannelService } from '@/services/channel-service';
import { PermissionService } from '@/services/common/permission.service';
import { ChannelMock } from './channel.mock';
import { I18nService } from '@cds/i18n';
import { DialogService } from '@/services/common/dialog.service';
import EditChannelDialog from './dialogs/edit-channel-dialog.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import AddChannelDialog from './dialogs/add-channel-dialog.vue';
import { ResultStatus } from '@/services/common/http.service';
import { DefaultFormData, getRules, DivisionOption } from './config';
// import { forEach } from 'lodash';
@Component({
  components: {
  }
})
export default class Channel extends Vue {
  public selectTagIds: any[] = [];
  public dynamicTags: any[] = [];
  public loading: boolean = false;
  private divisionOption: any[] = DivisionOption;
  // i8n
  @Inject(I18nService) private i18nSvc!: I18nService;

  private channelList: any[] = [];
  // 页面查询
  private searchParam = {
    page: 1,
    perPage: 10,
    total: 0
  };
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(ChannelService) private channelService!: ChannelService;
  @Inject(PermissionService) private PermissionService!: PermissionService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public created(): void {
    this.onQuery();
  }

  public async onEdit(row: any): Promise<void> {
    const res = await this.dialog.open(
      this.i18n.channel_mgmt.edit_channel,
      EditChannelDialog,
      row
    );
    if (res.action === CloseStatus.confirm) {
      this.onQuery();
    }
  }

  public async changeActive(row: any): Promise<void> {
    console.log(row);
    const param: any = { type: '', channelId: '' };
    if (row.enabled === 'N') {
      param.type = 'active';
    }
    param.channelId = row.id;
    await this.channelService.changeActive(param);
    this.$message({
      message: this.i18n.channel_mgmt.update_success,
      type: 'success'
    });
  }

  public async onCreate(): Promise<void> {
    const res = await this.dialog.open(
      this.i18n.channel_mgmt.add_channel,
      AddChannelDialog,
      {
        actionType: 'add'
      }
    );
    if (res.action === CloseStatus.confirm) {
      this.onQuery();
    }
  }
  // 权限
  public async onPermission(row: any): Promise<void> {
    this.selectTagIds = [];
    this.dynamicTags = [];
    this.$router.push({
      path: '/channel/permissions',
      query: {
        id: row.id,
        name: row.name,
        types: row.accessibilityScopeControlTypes,
        userScopes: row.userScopes
      }
    });
  }
  // 应用权限
  public async onAppPermission(row: any): Promise<void> {
      this.selectTagIds = [];
      this.dynamicTags = [];
      this.$router.push({
        path: '/permissionSon',
        query: {
          id: row.id,
          name: row.name,
          types: row.accessibilityScopeControlTypes,
          userScopes: row.userScopes
        }
      });
  }

  // onMenu 菜单
  public async onMenu(row: any): Promise<void> {
    console.log(row)
    this.$router.push({
      path: '/wechatMenu',
      query: {
        id: row.channelAgentId,
      }
    });
    localStorage.setItem('acceptChannelId', row.id)
  }
  // 自动回复
  public async onReply(row: any): Promise<void> {
    this.$router.push({
      path: '/wechatReply',
      query: {
        id: row.channelAgentId,
      }
    });
    localStorage.setItem('acceptChannelId', row.id)
  }
  public async advert(row: any): Promise<void> {
    this.$router.push({
      path: '/advertPage',
      query: {
        id: row.channelAgentId,
      }
    });
    localStorage.setItem('channelName', row.name)
  }

  public onSync(row: any): void {
    this.doAnync(row.channelAgentId);
  }

  public onDelete(row: any): void {
    const h = this.$createElement;
    this.$msgbox({
      title: this.i18n.channel_mgmt.delete,
      message: h('p', undefined, [
        h('span', undefined, `确认删除${row.name}?`)
      ]),
      showCancelButton: true,
      cancelButtonText: this.i18n.channel_mgmt.cancel,
      confirmButtonText: this.i18n.channel_mgmt.delete,
      confirmButtonClass: 'btn-color-red',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = this.i18n.channel_mgmt.doing;
          this.doDelete({
            channelId: row.id,
            agentId: row.channelAgentId
          });
          setTimeout(() => {
            done();
            setTimeout(() => {
              instance.confirmButtonLoading = false;
              this.onQuery();
            }, 300);
          }, 3000);
        } else {
          done();
        }
      }
    });
  }

  public handleSizeChange(pageParam: any): void {
    console.log('handleSizeChange', pageParam);
    this.searchParam.perPage = pageParam.pageSize;
    this.searchParam.page = pageParam.pageNum;
    this.onQuery();
  }
  public handleCurrentChange(currentPage: number): void {
    this.searchParam.page = currentPage;
    this.onQuery();
  }

  // -- CALL SERVICE -- //

  private async onQuery(): Promise<void> {
    this.loading = true;
    const param = {
      offset: this.searchParam.page,
      limit: this.searchParam.perPage
    };
    const list = await this.channelService.getChannels(param);
    const res = await this.PermissionService.findPermissionList();
    this.channelList = ChannelMock;
    console.log('308', list)
    if (list) {
      list.records.forEach((element: any) => {
        // mock
        // element.accessibilityScopeControlTypes.push('HR Core Org')
        // element.userScopes = ['1', '2', '3']
        let userScopes: any[] = [];
        element.userScopes.find((item: any) => {

          for(let i=0 ; i < this.divisionOption.length; i++){
            if(item == this.divisionOption[i].value){
              item = this.divisionOption[i].label
              break;
            }
          }

          // switch (item) {
          //   case '1':
          //     item = 'Pharma';
          //     break;
          //   case '3': // HR CORE
          //     item = 'HR Core';
          //     break;
          //   case '6': // NON FF
          //     item = 'NON FF';
          //     break;
          // }
          userScopes.push(item);
          element.userScopes = userScopes.join(', ');
        });
      });
      this.channelList = list.records;
      console.log('333',this.channelList);
      this.searchParam.total = list.total;
      // 应用对应权限
      this.channelList.forEach((element: any) => {
        let codes: any[] = [];
        res.forEach((item: any) => {
          if (element.id === item.applicationId) {
            codes.push(item.code);
            element.code = codes;
          }
        });
      });
    }
    await this.$nextTick();
    // this.fitTagCellWdith();
    this.loading = false;
  }
  // 同步
  private async doAnync(param: any): Promise<void> {
    await this.channelService.doAnync(param);
    this.$message({
      message: this.i18n.channel_mgmt.sync_success,
      type: ResultStatus.SUCCESS
    });
    this.onQuery();
  }
  // 删除
  private async doDelete(param: any): Promise<void> {
    const result = await this.channelService.delete(param);
    this.$message({
      message: this.i18n.channel_mgmt.delete_success,
      type: ResultStatus.SUCCESS
    });
    this.onQuery();
  }
  // private fitTagCellWdith(): void {
  //   const table = document.querySelector('table.el-table__body') as HTMLTableElement;
  //   (table.style as any)['table-layout'] = 'fixed';
  //   (table.style as any)['word-break'] = 'break-all';
  //   const tagCells: NodeListOf<HTMLDivElement> =
  //     document.querySelectorAll('div.tag-cell');
  //     // ;table-layout:fixed;word-break:break-all;
  //   forEach(tagCells, (tagCell: HTMLDivElement) => {
  //     (tagCell.parentElement as any).style.width = 'fit-content';
  //   });
  // }
}
