






























import { filter, forEach } from 'lodash';
import {
  Component,
  Emit,
  Prop,
  Ref,
  Vue as VueComponent
} from 'vue-property-decorator';
import { MaterialCategoryList } from './config';
import { IMaterialCategoryItem } from './material.interface';
import { MaterialType } from '@/components/rich-editor/editor.interface';


@Component
export default class MaterialMain extends VueComponent {
  public materialCategoryList: IMaterialCategoryItem[] = MaterialCategoryList;
  @Prop({
    // type: Number,
    type: String,
    default: ''
  })
  public type!: undefined;
  @Ref('target')
  public targets!: Vue[];
  public created(): void {
    this.materialCategoryList = filter(
      this.materialCategoryList,
      (e: IMaterialCategoryItem) => this.type === 0 || e.id === this.type
    );
  }
  public mounted(): void {
    forEach(this.targets, (target: Vue) => {
      target.$on('select', this.selectHandler.bind(this));
    });
  }
  @Emit('select')
  public selectHandler(data: any): void {
    return data;
  }
}
